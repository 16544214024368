import { defineStore } from 'pinia';

import PropertyTypeService from '@/services/property-type.service';

export const usePropertyTypeStore = defineStore({
    id: 'propertyType',
    state: () => ({
        propertyTypeModalOptions:{
            open:false,
            reference:null,
            title:'Nuevo Tipo de Propiedad',
            action:'create'
        },
        propertyTypeDefault: {
            name: '',
            active: true,
            principal:false,
            icon:null,
            file_icon:null,
            filters: []
        },
        propertyType :{
            name: '',
            active: true,
            principal:false,
            icon:null,
            file_icon:null,
            filters: []
        },
    }),
    getters: {
        statePropertyType: (state) => state.propertyType,
        statePropertyTypeDefault: (state) => state.propertyTypeDefault,
    },
    actions: {
        async findPropertyType(id){
            try {
                const propertyType = await PropertyTypeService.findPropertyType( id );
                this.propertyType = propertyType;
            } catch (error) {
                this.propertyType = null;
                throw error;
            }
        },
        async storePropertyType(propertyType) {
            try {
                await PropertyTypeService.storePropertyType( propertyType );
                this.propertyType = null;
            } catch (error) {
                this.propertyType = null;
                throw error;
            }
        },
        
        async updatePropertyType(propertyType){
            await PropertyTypeService.updatePropertyType( propertyType );
            this.propertyType ={ ...this.propertyTypeDefault };
        },
        async deletePropertyType(property_type_id){
            await PropertyTypeService.deletePropertyType( property_type_id );
        },
        async listFilters(){
            const filters = await PropertyTypeService.listFilters();
            this.propertyType.filters = filters;
            this.propertyTypeDefault.filters = filters;
        },
        restorePropertyType(){
            this.propertyType ={ ...this.propertyTypeDefault };
        },
        async listPropertyTypes(){
            return await PropertyTypeService.listPropertyTypes();
        },
        async listFiltersByPropertyType(property_type_id){
            return await PropertyTypeService.listFiltersByPropertyType(property_type_id);
        }
    }
});
// const pinia = getActivePinia();
// if (pinia) {
//     pinia.use(({ store }) => {
//         if (store.$id === 'propertyType') {
//             store.listFilters();
//         }
//     });
// }