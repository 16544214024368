<template>
    <DefaultDialog :options="mediaModalOptions" @closeModal="closeModal" @confirmModal="closeModal" width="900px"
        :showCardActions="false" :padding="false" :loading="loading">
        <template v-slot:body>
            <v-card class="mx-4 mb-2" elevation="0">
                <v-tabs
                    v-model="tab"
                    align-tabs="center"
                    color="deep-purple-accent-4"
                    fixed-tabs
                >
                    <v-tab value="image">Imagenes</v-tab>
                    <v-tab value="plano">Planos</v-tab>
                    <v-tab value="youtube">Youtube</v-tab>
                </v-tabs>
                <v-tabs-window v-model="tab" style="min-height: 160px;max-height: 450px;overflow-y: scroll;">
                    <v-tabs-window-item value="image" > 
                        <ImageGrid :medias="medias.image" type="image" :property="property" :loading="loading" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="plano" > 
                        <ImageGrid :medias="medias.plano" type="plano" :property="property" :loading="loading" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="youtube" > 
                            <draggable 
                                v-model="medias.youtube" 
                                group="youtube_videos" 
                                @start="drag=true" 
                                @end="drag=false" 
                                item-key="id"
                                :move="checkMove"
                                @change="updatePosition"
                            >
                                <template #item="{element,index}">
                                    <v-col cols="12" class="d-flex align-center">
                                        <v-text-field v-model="element.value" label="URL" variant="underlined" required />
                                        <v-icon @click="addYoutubeMedia(element,property,index)" class="mx-2">mdi-content-save</v-icon>
                                        <v-icon @click="removeYotubeVideo(index,element)" class="mx-2">mdi-delete</v-icon>
                                    </v-col>
                                </template>
                            </draggable>
                            <v-col cols="12" class="d-flex justify-center">
                                <v-icon @click="addYotubeVideo">mdi-plus</v-icon>
                            </v-col>
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card>
        </template>
    </DefaultDialog>
</template>

<script setup>
/* IMPORTS */
import { ref, defineExpose } from 'vue';
import { storeToRefs } from 'pinia'
import DefaultDialog from '@/components/basics/DefaultDialog.vue';
import ImageGrid from '@/components/basics/ImageGrid.vue';
import { usePropertyStore } from '@/stores'
import draggable from 'vuedraggable';

/* DATA */
// const emit = defineEmits(['confirm'])
const propertyStore = usePropertyStore();
const { mediaModalOptions } = storeToRefs(propertyStore)
// const step = ref(0);
const tab = ref('image');
const drag = ref(null);
let medias = ref({ ...propertyStore.medias });
const loading = ref(false);
let property = ref();
/*FUNCTIONS*/
const resetMedia = (_property) => {
    medias.value = { ...propertyStore.stateMedias };
    property.value = _property;
}
const closeModal = () => {
    mediaModalOptions.value.open = false;
    propertyStore.restoreMedia();
}

const addYotubeVideo = () => {
    medias.value.youtube.push({
        id: null,
        value: '',
        type:'youtube'
    })
}
const removeYotubeVideo = async(index,resource) => {
    await propertyStore.deleteMultimedia(property.value.id,resource);
    medias.value.youtube.splice(index, 1);
}
const addYoutubeMedia = async(media,_property,index) =>{
    const media_response = await propertyStore.saveMedia(_property.id,[media]);
    medias.value.youtube[index] = media_response[0];

}
const checkMove = (evt) =>{
    if(!evt.relatedContext.element.id){
        alert('Es necesario guardar para poder mover el elemento.');
    }
    return evt.relatedContext.element.id !== null;
}
const updatePosition = async (log) => {
    // const media_response = await propertyStore.saveMedia(_property.id,[media]);
    await propertyStore.updateMultimedia(property.value.id,{
        id:log.moved.element.id,
        position:log.moved.newIndex+1
    });
} 

defineExpose({
    resetMedia
})
</script>
<style scoped>
.drag-drop-area {
    border: 3px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: auto;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drag-drop-area p {
    margin-bottom: 10px;
}
</style>