<template>
    <DefaultDialog 
        :options="propertyTypeModalOptions"
        @closeModal="closeModal"
        @confirmModal="sendForm"
        width="500px"
        :loading="loading"
    >
        <template v-slot:body>
            <v-form  ref="propertyTypeForm">
                <v-row>
                    <v-col cols="12" class="d-flex">
                        <v-text-field 
                            v-model="propertyType.name"
                            label="Nombre" 
                            variant="underlined" 
                            required
                            :rules="rules.name"
                            class="mx-4"
                        />
                        <!-- <div
                            class="upload-area"
                            @click="triggerFileInput"
                            :style="{ backgroundImage: `url(${propertyType.icon})` }"
                            >
                            <input class="d-none" accept="image/*" ref="fileInput" type="file" @change="onFileChange($event)"  />
                            <div v-if="!propertyType.icon" class="placeholder">
                                Subir icono
                            </div>
                        </div> -->
                        <div
                            class="upload-area"
                            @click="triggerFileInput(index)"
                            >
                            <input class="d-none" accept="image/*" ref="fileInput" type="file" @change="onFileChange($event)"  />
                            <div v-if="!propertyType.icon" class="placeholder">
                                icono
                            </div>
                            <div v-else >
                                <img :src="propertyType.icon" alt="" height="35px" width="auto">
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <div class="card_border card" style="border-radius: 4px;">
                            <h5 class="card-text m-2 title-card-border">
                                Selecciona los filtros
                            </h5>
                            <v-row>
                                <draggable 
                                    v-model="propertyType.filters" 
                                    group="filters" 
                                    @start="drag=true" 
                                    @end="drag=false" 
                                    item-key="id"
                                >
                                    <template #item="{element}">
                                        <v-col cols="12" class="pb-0">
                                            <v-checkbox v-model="element.active" :label="element.name" density="compact" hide-details></v-checkbox>
                                        </v-col>
                                    </template>
                                </draggable>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-switch v-model="propertyType.principal" label="Mostrar como filtro principal" class="mr-3" color="primary" inset></v-switch>
                        <v-switch v-model="propertyType.active" label="Activo" color="primary" inset></v-switch>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </DefaultDialog>
</template>

<script setup>
    /* IMPORTS */
    import { ref,defineExpose,defineEmits,onBeforeMount } from 'vue';
    import { storeToRefs } from 'pinia'
    import DefaultDialog from '@/components/basics/DefaultDialog.vue';
    import { usePropertyTypeStore } from '@/stores'
    import {getRules,validateForm} from '@/composables/validations'
    import draggable from 'vuedraggable';
    import { useToast } from '@/composables/useToast';
    /* DATA */
    const { showToast } = useToast();

    const emit = defineEmits(['confirm'])
    const propertyTypeStore = usePropertyTypeStore();
    const { propertyTypeModalOptions } = storeToRefs(propertyTypeStore)
    const propertyTypeForm = ref(null);
    const loading = ref(false);
    const rules = {
        name: getRules(['required']),
        required: getRules(['required']),
    }
    const fileInput = ref(null);
    let propertyType = ref({ ...propertyTypeStore.statePropertyType });
    const drag = ref(false);
    // const filters = ref([]);
    /*FUNCTIONS*/
    const resetPropertyType = () =>{
        propertyType.value ={ ...propertyTypeStore.statePropertyType };
    }
    const closeModal = () =>{
        propertyTypeModalOptions.value.open = false;
        propertyTypeStore.restorePropertyType();
        propertyTypeStore.propertyTypeDefault.filters = propertyTypeStore.propertyTypeDefault.filters.map(f => {  f.active = false; return f});
        propertyType.value = {...propertyTypeStore.propertyTypeDefault};
    }
    const sendForm = async () => {
        const { valid } = await validateForm(propertyTypeForm);
        if (valid) {
            if (propertyType.value.id) {
                loading.value  = true;
                await propertyTypeStore.updatePropertyType(propertyType.value);
            } else {
                if(!propertyType.value.file_icon){
                    showToast('Es necesario añadir un icono','warning');
                    return;
                }
                loading.value  = true;
                await propertyTypeStore.storePropertyType(propertyType.value);
            }
            loading.value  = false;
            closeModal();
            emit('confirm')
        }
    };
    const triggerFileInput = () => {
      fileInput.value.click();
    };
    const onFileChange = (event) => {
      let file = event.target.files[0];
      console.log(event.target.files[0]);
      if (file) {
        propertyType.value.file_icon =file;
        // console.log(propertyType.value.file_image,file,'file');
        propertyType.value.icon = URL.createObjectURL(file);
      }
    }
    //EVENTS
    onBeforeMount(async () => {
        await propertyTypeStore.listFilters();
        propertyType.value = {...propertyTypeStore.propertyTypeDefault};
    })
    defineExpose({
        resetPropertyType
    })
</script>
<style>
.upload-area {
    width: 60px;
    height: 60px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    position: relative;
    font-size: 10px;
}
.upload-area .placeholder {
    text-align: center;
    font-size: 1.2em;
    color: #999;
}
.d-none {
    display: none;
}
.title-card-border{
	top: -10px;
	position: absolute; 
	background: white; 
	font-size: 12px;
}
.card_border {
    position: relative;
	padding: 15px;
	border-radius: 4px;
	border: 1px solid #D9D9D9; 
}
</style>