import axios from "@/plugins/axios";
import { formatFormData } from "@/composables/services.js";
class ClientService {
  async storeClient(client) {
    const clientFormData = formatFormData(client, "POST");
    const response = await axios
      .post("/manager/v1/clients", clientFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data.data;
      })
      .catch((e) => {
        throw e;
      });
    return response;
  }

  async updateClient(client) {
    const clientFormData = formatFormData(client,'PUT');
    const response = await axios
      .post(`/manager/v1/clients/${client.id}`, clientFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data.data;
      })
      .catch((e) => {
        throw e;
      });
    return response;
  }
  async deleteClient(client_id) {
    await axios.delete(`/manager/v1/clients/${client_id}`).then(() => {
      })
      .catch((e) => {
        throw e;
      });
  }
  async findClient(id) {
    let client = {};
    await axios
      .get(`/manager/v1/clients/${id}`)
      .then(({ data }) => {
        client = data.data;
      })
      .catch((e) => {
        throw e;
      });
    return client;
  }
}

export default new ClientService();
