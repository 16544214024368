import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";

export function useToast() {
  const showToast = (message, type = 'info') => {
    let background;
    switch (type) {
      case 'warning':
        background = "linear-gradient(to right, #ffa000, #ffca28)";
        break;
      case 'success':
        background = "linear-gradient(to right, #00b09b, #96c93d)";
        break;
      case 'error':
        background = "linear-gradient(to right, #d9534f, #d9534f)";
        break;
      default:
        background = "linear-gradient(to right, #00b09b, #96c93d)";
    }

    Toastify({
      text: message,
      duration: 3000,
      gravity: "top", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      className: type,
      style: {
        background: background,
      },
      onClick: function(){} // Callback after click
    }).showToast();
  };

  return {
    showToast,
  };
}
