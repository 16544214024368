<template>
    <DefaultDialog 
        :options="clientModalOptions"
        @closeModal="closeModal"
        @confirmModal="sendForm"
        width="900px"
        :loading="loading"
    >
        <template v-slot:body>
            <v-form  ref="clientForm">
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="client.name"
                                    label="Nombre" 
                                    variant="underlined" 
                                    required
                                    :rules="rules.name"
                                />
                            </v-col>
                            <v-col>
                                <v-switch v-model="client.show_in_clients" label="Mostrar en sección de clientes" color="primary" inset></v-switch>
                                <v-switch v-model="client.show_in_opinions" label="Mostrar en sección de opiniones" color="primary" inset></v-switch>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <div
                            class="upload-area"
                            @click="triggerFileInput"
                            :style="{ backgroundImage: `url(${client.image})` }"
                            >
                            <input class="d-none" accept="image/*" ref="fileInput" type="file" @change="onFileChange($event)"  />
                            <div v-if="!client.image" class="placeholder">
                                Haz clic para subir una imagen
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" v-if="client.show_in_opinions">
                        <v-textarea
                            v-model="client.opinion"
                            label="Opinión" 
                            variant="underlined" 
                            required
                        />
                    </v-col>
                </v-row>
                <v-col cols="12" class="d-flex justify-end">
                    <v-switch v-model="client.active" label="Activo" color="primary" inset></v-switch>
                </v-col>
            </v-form>
        </template>
    </DefaultDialog>
</template>

<script setup>
    /* IMPORTS */
    import { ref,defineExpose,defineEmits } from 'vue';
    import { storeToRefs } from 'pinia'
    import DefaultDialog from '@/components/basics/DefaultDialog.vue';
    import { useClientStore } from '@/stores'
    import {getRules,validateForm} from '@/composables/validations'
    import { useToast } from '@/composables/useToast';
    /* DATA */
    const { showToast } = useToast();
    const emit = defineEmits(['confirm'])
    const clientStore = useClientStore();
    const { clientModalOptions } = storeToRefs(clientStore)
    const clientForm = ref(null);
    const rules = {
        name: getRules(['required']),
        email: getRules(['required','email']),
        password: getRules(['required'])
    }
   
    let client = ref({ ...clientStore.stateClient });
    const fileInput = ref(null);
    const loading = ref(false);
    /*FUNCTIONS*/
    const resetClient = () =>{
        client.value ={ ...clientStore.stateClient };
    }
    const closeModal = () =>{
        clientModalOptions.value.open = false;
        clientStore.restoreClient();
        client.value = {...clientStore.clientDefault};
    }
    const sendForm = async () => {
        const { valid } = await validateForm(clientForm);
        if (valid) {
            if (client.value.id) {
                loading.value = true;
                await clientStore.updateClient(client.value);
            } else {
                if(!client.value.file_image){
                    showToast('Es necesario añadir una icono.', 'warning');
                    return;
                }
                loading.value = true;
                await clientStore.storeClient(client.value);
            }
            loading.value = false;
            closeModal();
            emit('confirm')
        }
    };
    const triggerFileInput = () => {
      fileInput.value.click();
    };
    const onFileChange = (event) => {
      let file = event.target.files[0];
      if (file) {
        client.value.file_image =file;
        // console.log(client.value.file_image,file,'file');
        client.value.image = URL.createObjectURL(file);
      }
    }
    defineExpose({
        resetClient
    })
</script>
<style scoped>
.upload-area {
  width: 100%;
  height: 300px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
}
.upload-area .placeholder {
  text-align: center;
  font-size: 1.2em;
  color: #999;
}
.d-none {
  display: none;
}
</style>