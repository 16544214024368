const openModal = (storeOptions,resource=null,title=null) => {
    storeOptions.open = true;
    const name = resource ? resource.name : '';
    if(title){
        storeOptions.title = title;
    }else{
        storeOptions.title = (resource && resource.id) ? `Editar ${name ? ' - '+name : ''}`  : 'Crear';
        storeOptions.action = (resource && resource.id) ? 'edit' : 'create';
    }
    if(resource){
        storeOptions.resource = resource;
    }
};
const closeModal = (storeOptions) =>{
    storeOptions.open = false;
}
const refreshTable = (reference)=>{
    reference.getData()
} 
export {
    openModal,
    closeModal,
    refreshTable
};
