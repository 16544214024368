const TypeOf = (x) => {
  // console.log(x)
  if (x === null) {
      return 'null';
  }

  switch (typeof x) {
      case "undefined":
          return "undefined";
      case "boolean":
          return "boolean";
      case "number":
          return "number";
      case "string":
          return "string";
      default:
          if (Array.isArray(x)) return "array";
          return "object";
  }
}
const formatFormData = (resource, method = "PUT") => {
  let resourceFormData = new FormData();
  resourceFormData.append("_method", method);
  const keys_resource = Object.keys(resource);

  for (let key in resource) {
    if (keys_resource.includes(key)) {
      if(key.includes('file')){
        resourceFormData.append(key, resource[key]);
        continue;
      }
      if (resource[key] == null) {
        resourceFormData.append(key, "");
      } else {
        if (typeof resource[key] === "object") {
          if (TypeOf(resource[key]) === "array") {
            for (let i in resource[key]) {
              let value = resource[key][i];
              if(TypeOf(value) === 'object'){
                for (const key_value in value) {
                  resourceFormData.append(`${key}[${i}][${key_value}]`, value[key_value]);
                }
              }else{
                resourceFormData.append(key +'[]', value);
              }
            }
          } else if (TypeOf(resource[key]) === "object" && !resourceFormData.get(key)) {
            let value = resource[key].id

            resourceFormData.append(key, value);
          }
        } else {
          resourceFormData.append(key, resource[key]);
        }
      }
    }
  }
  return resourceFormData;
};

export { formatFormData };