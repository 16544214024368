import axios from '@/plugins/axios';

class PropertyService {
  async storeProperty(property) {
    const response = await axios
      .post('/manager/v1/properties', property)
      .then(({ data }) => {
        return data.data;
      }).catch((e) => {
        throw e;
      });
    return response;
  }

  async updateProperty(property) {
    const response = await axios
      .put(`/manager/v1/properties/${property.id}`, property)
      .then(({ data }) => {
        return data.data;
      }).catch((e) => {
        throw e;
      });
    return response;
  }

  async findProperty(id) {
    let property = {};
    await axios
      .get(`/manager/v1/properties/${id}`)
      .then(({ data }) => {
        property = data.data;
      }).catch((e) => {
        throw e;
      });
    return property;
  }
  async listMedias(property_id) {
    let medias = [];
    await axios
      .get(`/manager/v1/properties/${property_id}/multimedia`)
      .then(({ data }) => {
        medias = data.data.multimedia;
      }).catch((e) => {
        throw e;
      });
    return medias;
  }
  async saveMedias(property_id, medias=[]){
    let _medias = [];
    let mediaFormData = new FormData();
    medias.forEach((m,index) => {
      mediaFormData.append(`multimedia[${index}][id]`,m.id);
      mediaFormData.append(`multimedia[${index}][type]`,m.type);
      (m.file_multimedia) && mediaFormData.append(`multimedia[${index}][file_multimedia]`,m.file_multimedia);
      (m.value) && mediaFormData.append(`multimedia[${index}][value]`,m.value);
    });
    await axios
      .post(`/manager/v1/properties/${property_id}/multimedia`,mediaFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        _medias = data.data.multimedia;
      }).catch((e) => {
        throw e;
      });
    return _medias;
  }
  async updateMultimedia(property_id,resource){
    const response = await axios
      .put(`/manager/v1/properties/${property_id}/multimedia/${resource.id}`, resource)
      .then(({ data }) => {
        return data.data;
      }).catch((e) => {
        throw e;
      });
    return response;
  }
  async deleteMultimedia(property_id,resource){
    await axios
    .delete(`/manager/v1/properties/${property_id}/multimedia/${resource.id}`)
    .then(({ data }) => {
      return data.data;
    }).catch((e) => {
      throw e;
    });
  }
  async listCities(){
    let cities = [];
    await axios.get('/manager/v1/filters/departments').then(({data})=>{
      cities = data.data;
    }).catch((e)=>{
      throw e;
    })
    return cities;
  }
  async listProvincesByCity(city_id){
    let provinces = [];
    await axios.get(`/manager/v1/filters/provinces?department_id=${city_id}`).then(({data})=>{
      provinces = data.data;
    }).catch((e)=>{
      throw e;
    })
    return provinces;
  }
  async listDistrictsByProvince(province_id){
    let districts = [];
    await axios.get(`/manager/v1/filters/districts?province_id=${province_id}`).then(({data})=>{
      districts = data.data;
    }).catch((e)=>{
      throw e;
    })
    return districts;
  }
  async deleteProperty(property_id){
    await axios.delete(`/manager/v1/properties/${property_id}`).then(()=>{
    }).catch((e)=>{
      throw e;
    })
  }
}

export default new PropertyService();
