<template>
    <v-card class="elevation-0 rounded-sm">
        <v-card-text>
            <v-row>
                <v-col cols="12" class="d-flex">
                    <h1 style="font-size: 16px;">Listado de usuarios</h1>
                    <v-spacer></v-spacer>
                    <DefaultButton
                        label="Crear Usuario"
                        variant='outlined'
                        @click="openModal(userStore.userModalOptions)"
                    />
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12">
                    <DefaultDataTable 
                        ref="userTable"
                        :data-table="dataTable"
                        :filters="filters"
                        @edit="editUser"
                        @delete="deleteResource"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <UserFormModal
            ref="userFormModal"
            @confirm="refreshTable(userTable)"
        />
        <DefaultDeleteDialog 
            ref="deleteDialog"
            @deleted="acceptDelete"
        />
    </v-card>    
</template>
<script setup>
    import {ref} from 'vue';
    import DefaultDataTable from '@/components/basics/DefaultDataTable.vue';
    import DefaultButton from '@/components/basics/DefaultButton.vue';
    import { openModal,refreshTable } from '@/composables/modal';
    import UserFormModal from './UserFormModal';
    import DefaultDeleteDialog from '@/components/basics/DefaultDeleteDialog.vue';
    import { useUserStore } from '@/stores';
    const userTable = ref(null);
    const deleteDialog = ref(null);

    const dataTable = {
        endpoint: '/manager/v1/users',
        headers: [
            {title: "NOMBRE COMPLETO", value: "full_name", align: 'center', sortable: false},
            {title: "EMAIL", value: "email"},
            {title: "FECHA DE CREACIÓN", value: "created_at", align: 'center', sortable: false},
            {title: "Opciones", value: "actions", align: 'center', sortable: false},
        ],
        actions: [
            {
                text: "Editar",
                icon: 'mdi mdi-pencil',
                type: 'action',
                method_name: 'edit'
            },
            {
                text: "Eliminar",
                icon: 'mdi mdi-delete',
                type: 'action',
                method_name: 'delete'
            }
        ],
        more_actions: [
        ]
    }
    const filters = {
        q: '',
    };
    const userStore = useUserStore();
    const userFormModal = ref(null);
    const editUser = async (user)=>{
        await userStore.findUser(user.id);
        userFormModal.value.resetUser();
        openModal(userStore.userModalOptions,userStore.user)
    }
    const deleteResource = (user) =>{
        deleteDialog.value.openDialog(user);
    }
    const acceptDelete = async(user) =>{
        await userStore.deleteUser(user.id);
        refreshTable(userTable.value);
    }
</script>