import axios from '@/plugins/axios';
class AuthService {
  async login(user) {
    const response = await axios
      .post('/manager/v1/auth/login', {
        identifier: user.identifier,
        password: user.password
      })
      .then(({data}) => {
        console.log('token',data.data);
        // updateToken(data.data.token);
        return data.data;
      }).catch((e)=>{
        throw e;
      });
    return response;
  }

  async logout() {
    await axios.post('/manager/v1/auth/logout').then(() => {
      localStorage.removeItem('user');
    }).catch((e)=>{
      throw e;
    });
  }
}

export default new AuthService();
