<template>
    <DefaultDialog 
        :options="userModalOptions"
        @closeModal="closeModal"
        @confirmModal="sendForm"
        :loading="loading"
    >
        <template v-slot:body>
            <v-form  ref="userForm">
                <v-row>
                    <v-col md="6" cols="12">
                        <v-text-field 
                            v-model="user.name"
                            label="Nombre" 
                            variant="underlined" 
                            required
                            :rules="rules.name"
                        />
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-text-field 
                            v-model="user.lastname"
                            label="Apellido Paterno" 
                            variant="underlined" 
                            required
                            :rules="rules.name"
                        />
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-text-field 
                            v-model="user.surname"
                            label="Apellido Materno" 
                            variant="underlined" 
                            required
                            :rules="rules.name"
                        />
                    </v-col>
                    <v-col md="6" cols="12">
                        <v-text-field 
                            v-model="user.email"
                            label="Email" 
                            variant="underlined" 
                            required
                            :rules="rules.email"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field 
                            v-model="user.password"
                            label="Contraseña" 
                            variant="underlined" 
                            required
                            :rules="(user.id) ? [] : rules.password"
                        />
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-switch v-model="user.active" label="Activo" color="primary" inset></v-switch>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </DefaultDialog>
</template>

<script setup>
    /* IMPORTS */
    import { ref,defineExpose,defineEmits } from 'vue';
    import { storeToRefs } from 'pinia'
    import DefaultDialog from '@/components/basics/DefaultDialog.vue';
    import { useUserStore } from '@/stores'
    import {getRules,validateForm} from '@/composables/validations'
    /* DATA */
    const emit = defineEmits(['confirm'])
    const userStore = useUserStore();
    const { userModalOptions } = storeToRefs(userStore)
    const userForm = ref(null);
    const rules = {
        name: getRules(['required']),
        email: getRules(['required','email']),
        password: getRules(['required','min:8'])
    }
   
    let user = ref({ ...userStore.stateUser });
    const loading = ref(false);
    /*FUNCTIONS*/
    const resetUser = () =>{
        user.value ={ ...userStore.stateUser };
    }
    const closeModal = () =>{
        userModalOptions.value.open = false;
        userStore.restoreUser();
        user.value = {...userStore.userDefault};
    }
    const sendForm = async () => {
        const { valid } = await validateForm(userForm);
        if (valid) {
            loading.value = true;
            if (user.value.id) {
                await userStore.updateUser(user.value);
            } else {
                await userStore.storeUser(user.value);
            }
            loading.value = false;
            closeModal();
            emit('confirm')
        }
    };
    defineExpose({
        resetUser
    })
</script>