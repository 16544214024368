import { ref, watch, onMounted } from 'vue';

export default function useGoogleMaps() {
    const query = ref('');
    const places = ref([]);
    let map = null;
    // let marker = null;
    let draggableMarker = null;
    const mapContainer = ref(null);
    let _city = ref('')
    let _pronvince = ref('');
    let map_latitude = ref('');
    let map_longitude = ref('');
    let _district = ref('')
    let mapLoaded = false;
    const clearMap = () => {
        query.value = '';
        places.value = [];
        map = null;
        // let marker = null;
        draggableMarker = null;
        mapContainer.value = false;
        _city.value = '';
        _pronvince.value = '';
        _district.value = '';
        mapLoaded = false;
    }
    const loadGoogleMaps = () => {
        return new Promise((resolve, reject) => {
            if (!mapLoaded) {
                if (document.getElementById('google-maps-script')) {
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAAGJ6lUUhCWlCvoMPn0aIAVGeIqqCkzKM&loading=async&libraries&libraries=places`;
                script.id = 'google-maps-script';
                script.async = true;
                script.defer = true;
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
                mapLoaded = true;
            }
        });
    };

    const initMap = async () => {
        const mapElement = document.getElementById('map');
        let longitude = document.getElementById('longitude');
        let latitude = document.getElementById('latitude');
        longitude = longitude ? longitude.value : '-69.397096';
        latitude = latitude ? latitude.value : '-76.9797599';
        map_longitude.value = longitude;
        map_latitude.value = latitude;

        if (mapElement && !mapElement.querySelector('.gm-style')) {
            const { Map } = await window.google.maps.importLibrary("maps");
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
            map = new Map(document.getElementById("map"), {
                zoom: 20,
                center: { lat: +latitude, lng: +longitude },
                mapId: "map",
            });

            draggableMarker = new AdvancedMarkerElement({
                map: map,
                draggable: true,
                position: { lat: +latitude, lng: +longitude },
                gmpDraggable: true,
            });
            draggableMarker.addListener('dragend', () => {
                query.value = '';
                const newPosition = draggableMarker.position;
                console.log(draggableMarker,'draggableMarker');
                map.setCenter(newPosition);
                updateQueryWithMarkerPosition(newPosition);
            });
        }
    };

    const updateQueryWithMarkerPosition = (position) => {
        console.log(position,'updateQueryWithMarkerPosition');
        map_longitude.value = position.Gg;
        map_latitude.value = position.Fg;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: position }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    query.value = results[0].formatted_address;
                    console.log(query.value);
                } else {
                    console.log('no results');
                }
            } else {
                console.log('not ok');
            }
        });
    };

    const searchPlaces = () => {
        if (!query.value) {
            return;
        }
        const service = new window.google.maps.places.PlacesService(map);
        service.textSearch({ query: query.value }, (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                places.value = results;
            }
        });
    };

    const setMarker = async (place) => {
        query.value = place.name;
        places.value = [];
        if (draggableMarker) {
            draggableMarker.setMap(null);
        }
        const { AdvancedMarkerElement } = await window.google.maps.importLibrary("marker");
        draggableMarker = new AdvancedMarkerElement({
            map: map,
            draggable: true,
            position: place.geometry.location,
            gmpDraggable: true,
        });
        draggableMarker.addListener('dragend', () => {
            query.value = '';
            const newPosition = draggableMarker.position;
            map.setCenter(newPosition);
            updateQueryWithMarkerPosition(newPosition);
            console.log(draggableMarker,'draggableMarker');
        });
        map.setCenter(place.geometry.location);
        map.setZoom(25);
        const geocoder = new window.google.maps.Geocoder();
        await geocoder.geocode({ location: place.geometry.location }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const city = results[0].address_components.find(component => component.types.includes('administrative_area_level_1'));
                    const pronvince = results[0].address_components.find(component => component.types.includes('administrative_area_level_2'));
                    const district = results[0].address_components.find(component => component.types.includes('locality'));
                    if (district) {
                        _district.value = district.long_name;
                    }
                    if (city) {
                        _city.value = city.long_name;
                    }
                    if(pronvince){
                        _pronvince.value = pronvince.long_name;
                    }
                }
            }
        });
        map_latitude.value = place.geometry.location.lat();
        map_longitude.value = place.geometry.location.lng();
        return {
            city: _city.value,
            pronvince: _pronvince.value,
            district: _district.value,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
        }
    };
    watch(mapContainer, () => {
        initMap();
    });

    onMounted(async () => {
        try {
            await loadGoogleMaps();
        } catch (error) {
            console.error('Error al cargar la API de Google Maps:', error);
        }
    });

    return {
        query,
        places,
        mapContainer,
        map_latitude,
        map_longitude,
        searchPlaces,
        setMarker,
        clearMap
    };
}
