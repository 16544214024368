<template>
  <v-container class="h-screen">
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12 mt-12 rounded-lg">
          <v-card-title class="primary text-center">
            <span>GRIM MASTER BACKOFFICE</span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login" ref="loginForm">
              <v-row>
                <v-col cols="12" class="text-error" v-if="errorMessage">
                  <span>{{ errorMessage }}</span>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="identifier" label="Correo electrónico" variant="underlined" required autofocus
                    :rules="rules.email" />
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="password" label="Contraseña" type="password" variant="underlined" required
                    :rules="rules.password" />
                </v-col>
                <v-col cols="12">
                  <v-btn type="submit" color="primary" block :disabled="isLoading">
                    Iniciar sesión
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
/*IMPORTS*/
import { ref } from 'vue';
import { useAuthStore } from '@/stores';
import { getRules, validateForm } from '@/composables/validations'
/*DATA*/
const identifier = ref('');
const password = ref('');
const loginForm = ref(null);
let errorMessage = ref('');

let isLoading = ref(false);

const rules = {
  email: getRules(['required', 'email']),
  password: getRules(['required']),
}
/*FUNCTIONS*/
const login = async () => {
  const { valid } = await validateForm(loginForm);
  if (valid) {
    const authStore = useAuthStore();
    isLoading.value = true;
    try {
      await authStore.login(identifier.value, password.value);
      isLoading.value = false;
    } catch (error) {
      isLoading.value = false;
      errorMessage.value = 'Credenciales incorrectas';
      console.log(errorMessage);
    }
  }
};
</script>
<style scoped>
.text-error {
  text-align: center;
  color: red;
  font-weight: bolder;
}
</style>