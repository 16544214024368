<template>
    <v-card class="elevation-0 rounded-sm">
        <v-card-text>
            <v-row>
                <v-col cols="12" class="d-flex">
                    <h1 style="font-size: 16px;">Listado de clientes</h1>
                    <v-spacer></v-spacer>
                    <DefaultButton
                        label="Crear Cliente"
                        variant='outlined'
                        @click="openModal(clientStore.clientModalOptions)"
                    />
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12">
                    <DefaultDataTable 
                        ref="clientTable"
                        :data-table="dataTable"
                        :filters="filters"
                        @edit="editClient"
                        @delete="deleteResource"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <ClientFormModal
            ref="clientFormModal"
            @confirm="refreshTable(clientTable)"
        />
        <DefaultDeleteDialog 
            ref="deleteDialog"
            @deleted="acceptDelete"
        />
    </v-card>    
</template>
<script setup>
    import {ref} from 'vue';
    import DefaultDataTable from '@/components/basics/DefaultDataTable.vue';
    import DefaultButton from '@/components/basics/DefaultButton.vue';
    import { openModal,refreshTable } from '@/composables/modal';
    import ClientFormModal from './ClientFormModal'
    import { useClientStore } from '@/stores';
    import DefaultDeleteDialog from '@/components/basics/DefaultDeleteDialog.vue';
    const clientTable = ref(null);
    const deleteDialog = ref(null);
    const dataTable = {
        endpoint: '/manager/v1/clients',
        headers: [
            {title: "Cliente", value: "name", align: 'center', sortable: false},
            {title: "FECHA DE CREACIÓN", value: "created_at", align: 'center', sortable: false},
            {title: "Opciones", value: "actions", align: 'center', sortable: false},
        ],
        actions: [
            {
                text: "Editar",
                icon: 'mdi mdi-pencil',
                type: 'action',
                method_name: 'edit'
            },
            {
                text: "Eliminar",
                icon: 'mdi mdi-delete',
                type: 'action',
                method_name: 'delete'
            }
        ],
        more_actions: [
        ]
    }
    const filters = {
        q: '',
    };
    const clientStore = useClientStore();
    const clientFormModal = ref(null);
    const editClient = async (user)=>{
        await clientStore.findClient(user.id);
        clientFormModal.value.resetClient();
        openModal(clientStore.clientModalOptions,clientStore.client)
    } 
    const deleteResource = (client) =>{
        deleteDialog.value.openDialog(client);
    }
    const acceptDelete = async(client) =>{
        await clientStore.deleteClient(client.id);
        refreshTable(clientTable.value);
    }
</script>