<template>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline">Confirmar</v-card-title>
        <v-card-text>¿Estás seguro de querer eliminarlo? Una vez eliminado no se podrá recuperar.</v-card-text>
        <v-card-actions class="d-flex justify-center">
          <DefaultButton label="Cancelar" @click="closeDialog" color="black" class="mx-6" />
          <DefaultButton label="Eliminar" @click="confirmDelete" variant="tonal" class="mx-6" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script setup>
  import { ref, defineExpose, defineEmits } from 'vue';
  import DefaultButton from './DefaultButton.vue';
  
  const dialog = ref(false);
  const resource = ref(null);
  const emit = defineEmits(['deleted']);
  
  const openDialog = (res) => {
    dialog.value = true;
    resource.value = res;
  };
  
  const closeDialog = () => {
    dialog.value = false;
    resource.value = null;
  };
  
  const confirmDelete = () => {
    emit('deleted', resource.value);
    closeDialog();
  };
  
  defineExpose({
    openDialog,
  });
  </script>
  
  <style scoped>
  /* Puedes agregar estilos personalizados aquí si es necesario */
  </style>
  