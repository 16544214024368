<template>
    <DefaultDialog 
        :options="filterModalOptions"
        @closeModal="closeModal"
        @confirmModal="sendForm"
        width="900px"
        :loading="loading"
    >
        <template v-slot:body>
            <v-form  ref="filterForm">
                <input class="d-none" accept="image/*" ref="fileInput" type="file" @change="onFileChange($event)"  />
                <v-row>
                    <v-col cols="6">
                        <v-row>
                            <v-col cols="12" class="d-flex">
                                <v-text-field 
                                    label="Nombre" 
                                    variant="underlined" 
                                    :rules="rules.name"
                                    v-model="filter.name"
                                    class="pr-4"
                                />
                                <div
                                    class="upload-area-circle"
                                    @click="triggerFileInput(index)"
                                    >
                                    <div v-if="!filter.icon" class="placeholder">
                                        icono
                                    </div>
                                    <div v-else >
                                        <img :src="filter.icon" alt="" height="35px" width="auto">
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="filter.input_type"
                                    label="Tipo"
                                    :items="types_filters"
                                    variant="outlined"
                                    :rules="rules.required"
                                    item-title="name"
                                    item-value="code"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" v-if="filter.input_type == 'select'">
                                <v-switch v-model="filter.multiple" label="¿Es selección múltiple?" color="primary" inset></v-switch>
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <div class="card_border card" :style="{ borderRadius: '4px'}">
                            <h5 class="card-text m-2 title-card-border">
                                Características
                            </h5>
                            <v-row v-if="filter.input_type == 'number_range'">
                                <!-- <v-col cols="6">
                                    <v-text-field 
                                        label="Mínimo" 
                                        variant="underlined" 
                                        required
                                        :rules="rules.required"
                                        v-model="filter.min"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        label="Máximo" 
                                        variant="underlined" 
                                        required
                                        :rules="rules.required"
                                        v-model="filter.max"
                                    />
                                </v-col> -->
                                <v-col cols="6">
                                    <v-text-field 
                                        label="Sufijo" 
                                        variant="underlined" 
                                        required
                                        v-model="filter.suffix"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field 
                                        label="Prefijo" 
                                        variant="underlined" 
                                        v-model="filter.prefix"
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="filter.input_type == 'select'" :style="{ overflow:filter.input_type === 'select' ? 'scroll' : 'none', height: filter.input_type === 'select' ? '380px' : '100%' }">
                               <v-col cols="12" v-for="(value,index) in filter.values" :key="index" class="d-flex align-center">
                                <v-text-field 
                                    label="Valor" 
                                    variant="underlined" 
                                    required
                                    :rules="rules.required"
                                    v-model="value.name"
                                    density="compact"
                                />
                                <v-icon @click="deleteOption(index)">mdi-minus</v-icon>
                               </v-col> 
                               <v-col cols="12" class="d-flex justify-center">
                                    <v-icon @click="addOption">mdi-plus</v-icon>
                               </v-col>
                            </v-row>
                        </div>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-switch v-model="filter.active" label="Activo" color="primary" inset></v-switch>
                    </v-col>
                </v-row>
            </v-form>
        </template>
    </DefaultDialog>
</template>

<script setup>
    /* IMPORTS */
    import { ref,defineExpose,defineEmits } from 'vue';
    import { storeToRefs } from 'pinia'
    import DefaultDialog from '@/components/basics/DefaultDialog.vue';
    import { useFilterStore } from '@/stores'
    import {getRules,validateForm} from '@/composables/validations'
    /* DATA */
    const emit = defineEmits(['confirm'])
    const filterStore = useFilterStore();
    const { filterModalOptions } = storeToRefs(filterStore)
    const filterForm = ref(null);
    const rules = {
        name: getRules(['required']),
        required: getRules(['required']),
    }
    const types_filters = [
        {name:'Rango',code:'number_range'},
        {name:'Seleccionable',code:'select'},        
    ];
    let filter = ref({ ...filterStore.stateFilter });
    const fileInput = ref(null);
    const loading = ref(false);
    /*FUNCTIONS*/
    const resetFilter = () =>{
        filter.value ={ ...filterStore.stateFilter };
    }
    const closeModal = () =>{
        filterModalOptions.value.open = false;
        filterStore.restoreFilter();
        filter.value = {...filterStore.filterDefault};
    }
    const sendForm = async () => {
        const { valid } = await validateForm(filterForm);
        if (valid) {
            loading.value = true;
            if (filter.value.id) {
                await filterStore.updateFilter(filter.value);
            } else {
                await filterStore.storeFilter(filter.value);
            }
            loading.value = false;
            closeModal();
            emit('confirm')
        }
    };
    const triggerFileInput = () => {
      fileInput.value.click();
    };
    const onFileChange = (event) => {
      let file = event.target.files[0];
      if (file) {
        filter.value.file_icon =file;
        filter.value.icon = URL.createObjectURL(file);
      }
    }
    const addOption = () => {
        filter.value.values.push({
            name:''
        });
    }
    const deleteOption =(index) => {
        filter.value.values.splice(index,1)
    }
    defineExpose({
        resetFilter
    })
</script>
<style scoped>
.upload-area-circle {
  width: 80px;
  height: 80px;
  border: 2px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
}
.upload-area-circle .placeholder {
  text-align: center;
  font-size: 1.2em;
  color: #999;
}
.d-none {
  display: none;
}
</style>