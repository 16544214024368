import { defineStore } from 'pinia';

import FilterService from '@/services/filter.service';
export const useFilterStore = defineStore({
    id: 'filter',
    state: () => ({
        filterModalOptions:{
            open:false,
            reference:null,
            title:'Nuevo Filtro'
        },
        filterDefault: {
            name : "",
            suffix : null,
            prefix:null,
            code : "",
            input_type : "number_range",
            icon : "",
            file_icon:null,
            color : "",
            values : [],
            multiple:false,
            active : true
        },
        filter: {
            name : "",
            suffix : null,
            prefix:null,
            code : "",
            input_type : "",
            icon : "",
            file_icon:null,
            color : "",
            values : [],
            multiple:false,
            active : true
        },
    }),
    getters: {
        stateFilter: (state) => state.filter,
    },
    actions: {
        async findFilter(id){
            try {
                const filter = await FilterService.findFilter( id );
                this.filter = filter;
            } catch (error) {
                this.filter = null;
                throw error;
            }
        },
        async storeFilter(filter) {
            try {
                await FilterService.storeFilter( filter );
                this.filter = null;
            } catch (error) {
                this.filter = null;
                throw error;
            }
        },
        async deleteFilter(filter_id){
            await FilterService.deleteFilter( filter_id );
        },
        async updateFilter(filter){
            await FilterService.updateFilter( filter );
            this.filter ={ ...this.filterDefault };
        },
        restoreFilter(){
            this.filter ={ ...this.filterDefault };
        }
    }
});
