<template>
    <v-btn 
        :class="[{ 'font-weight-bold': props.textBold },'mx-1','rounded-8']"
        :style="props.min_content ? 'max-width: min-content;' : 'width:100%;'" 
        :disabled="props.disabled" 
        :block="props.block"
        @click="onClick" 
        :icon="props.isIconButton" 
        :color="props.color"
        :flat="props.flat"
        :slim="props.slim"
        :size="props.size"
        :rounded="props.rounded"
        :loading="loading"
        :variant="variant"
    >
        <v-icon v-if="props.icon" :small="props.smallIcon" class="mr-1">{{ props.icon }}</v-icon>
        <span v-if="!props.isIconButton">{{ props.label }}</span>
        <v-icon v-if="props.appendIcon" :small="props.smallIcon" class="ml-1">{{ props.appendIcon }}</v-icon>
    </v-btn>
</template>

<script setup>
    import {  defineEmits,defineProps } from 'vue';
    const emit = defineEmits(['click'])
    const props = defineProps({
        color: {
            type:String,
            default:'primary'
        },
        label: {
            type: String,
            default: 'Botón'
        },
        icon: {
            type: String
        },
        appendIcon: {
            type: String
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        textBold: {
            type: Boolean,
            default: false
        },
        smallIcon: {
            type: Boolean,
            default: false
        },
        rounded:{
            type:String,
            default:'xs'
        },
        min_content:{
            type:Boolean,
            default:true,
        },
        isIconButton:{
            type:Boolean,
            default:false,
        },
        flat:{
            type:Boolean,
            default:false,
        },
        slim:{
            type:Boolean,
            default:false,
        },
        size:{
            type:String,
            default:'small'
        },
        loading:{
            type:Boolean,
            default:false,
        },
        variant:{
            type:String,
            default:'text' /*outlined-tonal-text-plain*/
        }
    })

    const onClick = () => {
        console.log('click button');
        emit('click');
    };
</script>s