
const getRules = (arrayRules) => {
    let tempRules = [];
    arrayRules.forEach((labelRule) => {
        if (labelRule.indexOf("required") > -1) {
            const tempRule = (v) => !!v || "Campo requerido";
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("required-strict") > -1) {
            const tempRule = (v) => {
                if (!v) return 'Campo requerido';
                if (!v.trim().length) return 'Campo requerido';

                return true;
            }
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("max") > -1) {
            let split = labelRule.split(":");
            let max = split[1];
            const tempRule = (v) =>
                (v && v.length <= max) || `El campo debe tener menos de ${max} caracteres`;
            tempRules.push(tempRule);
        }
        if (labelRule.indexOf("only_max") > -1) {
            let split = labelRule.split(":");
            let max = split[1];
            const tempRule = (v) => {
                if (v) return (v.length <= max) || `El campo debe tener menos de ${max} caracteres`;
                else return true;
            }
            tempRules.push(tempRule);
        }
        if (labelRule.indexOf("min") > -1) {
            let split = labelRule.split(":");
            let min = split[1];
            const tempRule = (v) =>
                (v && v.length >= min) || `El campo debe tener más de ${min} caracteres`;
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("text") > -1) {
            const tempRule = (v) => /^[a-zA-ZáéíóúñÁÉÍÓÚÑ ]+$/i.test(v) || "No se permiten números";
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("number") > -1) {
            const tempRule = (v) => /^[0-9]+$/i.test(v) || "No se permiten letras";
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("24hr") > -1) {
            const tempRule = (v) =>
                /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/i.test(v) ||
                "El formato de la hora debe ser de 24 horas";
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("min_value") > -1) {
            let split = labelRule.split(":");
            let min = split[1];
            const tempRule = (v) => (v && v >= min) || `El valor debe ser mayor a ${min}`;
            tempRules.push(tempRule);
        }

        if (labelRule.indexOf("max_value") > -1) {
            let split = labelRule.split(":");
            let max = split[1];
            const tempRule = (v) => (v && v >= max) || `El valor debe ser menor a ${max}`;
            tempRules.push(tempRule);
        }
        if (labelRule.indexOf("email") > -1) {
            const tempRule = (v) =>
                /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "El formato de correo electrónico es inválido";
            tempRules.push(tempRule);
        }
    });
    return tempRules;
};
const validateForm = async (loginForm) => {
    return await loginForm.value?.validate()
};
export {
    getRules,
    validateForm
};
