import { createApp } from 'vue'
import App from './App.vue'
import axiosDefault from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import { createPinia } from 'pinia';
import {router} from "./router";


const pinia = createPinia()
const app = createApp(App)
app.use(pinia)
app.use(vuetify);
app.use(router);
app.config.globalProperties.$http = axiosDefault;
app.mount('#app')