import axios from "@/plugins/axios";
import { formatFormData } from "@/composables/services.js";
class FilterService {
  async storeFilter(filter) {
    const filterFormData = formatFormData(filter, "POST");
    const response = await axios
      .post("/manager/v1/filters", filterFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data.data;
      })
      .catch((e) => {
        throw e;
      });
    return response;
  }

  async updateFilter(filter) {
    const filterFormData = formatFormData(filter,"PUT");
    const response = await axios
      .post(`/manager/v1/filters/${filter.id}`, filterFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        return data.data;
      })
      .catch((e) => {
        throw e;
      });
    return response;
  }
  async deleteFilter(filter_id){
    await axios.delete(`/manager/v1/filters/${filter_id}`).then(() => {
    })
    .catch((e) => {
      throw e;
    });
  }
  async findFilter(id) {
    let filter = {};
    await axios
      .get(`/manager/v1/filters/${id}`)
      .then(({ data }) => {
        filter = data.data;
      })
      .catch((e) => {
        throw e;
      });
    return filter;
  }
}

export default new FilterService();
