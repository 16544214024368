import axios from '@/plugins/axios';

class AuthService {
  async storeUser(user) {
    const response = await axios
      .post('/manager/v1/users', {
        name: user.name,
        lastname: user.lastname,
        surname: user.surname,
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation,
        active: user.active
      })
      .then(({ data }) => {
        return data.data;
      }).catch((e) => {
        throw e;
      });
    return response;
  }

  async updateUser(user) {
    const response = await axios
      .put(`/manager/v1/users/${user.id}`, {
        name: user.name,
        lastname: user.lastname,
        surname: user.surname,
        email: user.email,
        password: user.password,
        password_confirmation: user.password_confirmation,
        active: user.active
      })
      .then(({ data }) => {
        return data.data;
      }).catch((e) => {
        throw e;
      });
    return response;
  }
  async deleteUser(user_id) {
    await axios.delete(`/manager/v1/users/${user_id}`).then(() => {
    }).catch((e) => {
      throw e;
    });
  }
  async findUser(id) {
    let user = {};
    await axios
      .get(`/manager/v1/users/${id}`)
      .then(({ data }) => {
        user = data.data;
      }).catch((e) => {
        throw e;
      });
    return user;
  }
}

export default new AuthService();
