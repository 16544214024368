<template>
  <v-row class="mt-1">
    <v-col cols="4" style="height: 100%;">
          <div class="drag-drop-area" @dragover.prevent @drop="handleDrop"
          @click="openFileInput">
          <p>Arrastra y suelta archivos aquí o haz clic</p>
          <input class="d-none" accept="image/*" ref="fileInput" type="file"
              @change="handleFileUpload($event)" multiple />
      </div>
  </v-col>
    <v-col
      v-for="(image, index) in medias" :key="index" cols="4" style="height: 250px;" 
      draggable="true"
      @dragstart="handleDragStart(index)" 
      @drop="handleImgDrop(index)"
      @dragover.prevent
    >
      <v-hover v-slot="{ isHovering, props }">
        <div
          class="d-flex align-center flex-column position-relative border-thin"
          v-bind="props"
        >
          <div v-if="image.to_delete" class="position-absolute align-self-start ml-2 mt-1 text-white bg-red rounded text-caption" style="z-index:10">
            <span class="px-3">Eliminado</span>
          </div>
          <div v-if="isHovering" class="position-absolute align-self-end" style="z-index:10" >
            <v-btn
              v-if="!image.to_delete"
              class="ma-2"
              color="indigo"
              icon="mdi-delete"
              size="x-small"
              @click="removeImage(index,image)"
            ></v-btn>
            <v-tooltip
              location="top"
              v-if="image.to_delete"
            >
              <template v-slot:activator="{ props }">
                  <v-btn
                    class="ma-2"
                    color="red"
                    icon="mdi-alert"
                    size="x-small"
                    @click="recoveryImage(index,image)"
                    v-bind="props"
                  ></v-btn>
              </template>
              <span  >Está image se eliminará hoy a media noche, sí deseas recuperarla da click al icono</span>
            </v-tooltip>
          </div>
          <v-skeleton-loader type="image">
            <v-img :width="250" height="200px" :src="image.value"
              class="image-with-skeleton"
            >
              <template #placeholder>
                <v-skeleton-loader type="image" :width="250" height="200"></v-skeleton-loader>
              </template>
            </v-img>
          </v-skeleton-loader>
          <div>
            <input type="text" v-model="image.description" class="styled-input mb-3">
            <v-btn
              class="ma-2"
              color="primary"
              icon="mdi-content-save"
              size="x-small"
              @click="saveDescription(image)"
            ></v-btn>
          </div>
        </div>
      </v-hover>
    </v-col>

  </v-row>
</template>

<script setup>
import { ref, toRefs, defineProps } from 'vue';
import {chunkArray} from '@/composables/mixins';
import { usePropertyStore } from '@/stores'

const props = defineProps({
  medias: {
    type: Array,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  property:{
    type:Object,
    required:true
  }
});
const propertyStore = usePropertyStore();

const { medias,property } = toRefs(props);
const draggingIndex = ref(null);
const selectedFile = ref(null);
const fileInput = ref(null);

const openFileInput = () => {
  fileInput.value.click();
};

const handleFileUpload = (event) => {
  const files = event.target.files;
  if (files) {
    selectedFile.value = Array.from(files);
  }
  // Obtener las URL de los archivos cargados y agregarlas a la matriz
  let _medias = [];

  selectedFile.value.forEach((file) => {
    _medias.push({
      id: null,
      value: null,
      file_multimedia: file,
      type: props.type,
      description:null
    });
  });

  const filesToSend = chunkArray(_medias,5);
  filesToSend.forEach(async (files) => {
    const _response_medias = await propertyStore.saveMedia(property.value.id,files);
    _response_medias.map((rm)=> medias.value.push(rm))
    // medias.value = [...medias, ..._response_medias];
  });
};

const saveDescription= (image) => {
  updateMultimedia({
    id:image.id,
    description:image.description,
  });
}

const updateMultimedia = async (image)=>{
  await propertyStore.updateMultimedia(property.value.id,image);
}
const handleDragStart = (index) => {
  draggingIndex.value = index;
};

const handleImgDrop = (dropIndex) => {
  const draggedImg = medias.value.splice(draggingIndex.value, 1)[0];
  medias.value.splice(dropIndex, 0, draggedImg);
  /*update position in BD*/
  const finalIndex = medias.value.findIndex(m => m.id == draggedImg.id);
  updateMultimedia({
    id:draggedImg.id,
    position: finalIndex + 1,
  });
  draggingIndex.value = null; 
};

const removeImage = (index,image) => {
  medias.value[index].to_delete = true;
  updateMultimedia({
    id:image.id,
    to_delete:image.to_delete,
  });

};
const recoveryImage = (index,image) => {
  medias.value[index].to_delete = false;
  updateMultimedia({
    id:image.id,
    to_delete:image.to_delete,
  });
}
const handleDrop = (event) => {
  event.preventDefault();
  selectedFile.value = event.dataTransfer.files;
  handleFileUpload(event);
};
</script>
<style scoped>
.drag-drop-area {
  border: 3px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  width: auto;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-drop-area p {
  margin-bottom: 10px;
}
.styled-input {
    width: 80%; 
    font-size: 16px; 
    border: 2px solid #ccc;
    border-radius: 5px; 
    box-sizing: border-box; 
    margin-top:10px;
}
.image-with-skeleton {
  position: relative;
  overflow: hidden;
}
</style>