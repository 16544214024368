import { defineStore } from 'pinia';

import UserService from '@/services/user.service';

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        userModalOptions:{
            open:false,
            reference:null,
            title:'Crear usuario'
        },
        userDefault: {
            id:null,
            name: null,
            lastname: null,
            surname: null,
            email: null,
            password: null,
            active : null
        },
        user: {
            id:null,
            name: null,
            lastname: null,
            surname: null,
            email: null,
            password: null,
            active : null
        },
    }),
    getters: {
        stateUser: (state) => state.user,
    },
    actions: {
        async findUser(id){
            try {
                const user = await UserService.findUser( id );
                this.user = user;
            } catch (error) {
                this.user = null;
                throw error;
            }
        },
        async storeUser(user) {
            try {
                await UserService.storeUser( user );
                this.user = null;
            } catch (error) {
                this.user = null;
                throw error;
            }
        },
        async deleteUser(user_id){
            await UserService.deleteUser( user_id );
        },
        async updateUser(user){
            await UserService.updateUser( user );
            this.user ={ ...this.userDefault };
        },
        restoreUser(){
            this.user ={ ...this.userDefault };
        }
    }
});
