import { defineStore } from 'pinia';

import ClientService from '@/services/client.service';
export const useClientStore = defineStore({
    id: 'client',
    state: () => ({
        clientModalOptions:{
            open:false,
            reference:null,
            title:'Nuevo Cliente'
        },
        clientDefault: {
            id:null,
            name: null,
            show_in_clients:true,
            show_in_opinions:false,
            image: null,
            file_image:null,
            opinion: null,
            active : false,
            order:1
        },
        client: {
            id:null,
            name: null,
            show_in_clients:true,
            show_in_opinions:false,
            image: null,
            file_image:null,
            opinion: null,
            active : false,
            order:1
        },
    }),
    getters: {
        stateClient: (state) => state.client,
    },
    actions: {
        async findClient(id){
            try {
                const client = await ClientService.findClient( id );
                this.client = client;
            } catch (error) {
                this.client = null;
                throw error;
            }
        },
        async storeClient(client) {
            try {
                await ClientService.storeClient( client );
                this.client = null;
            } catch (error) {
                this.client = null;
                throw error;
            }
        },
        
        async updateClient(client){
            await ClientService.updateClient( client );
            this.client ={ ...this.clientDefault };
        },
        async deleteClient(client_id){
            await ClientService.deleteClient( client_id );
        },
        restoreClient(){
            this.client ={ ...this.clientDefault };
        }
    }
});
