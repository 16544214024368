import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores';
import User from '@/layouts/user/UserPage.vue';
// import App from '@/App.vue';
import Login from '@/layouts/login/LoginPage.vue';
import Property from '@/layouts/properties/PropertyLayout.vue';
import Analytics from '@/layouts/analytics/AnalyticsLayout.vue';
import Client from '@/layouts/client/ClientLayout.vue';
import PropetyType from '@/layouts/property-type/PropertyTypeLayout.vue';
import FilterLayout from '@/layouts/filters/FilterLayout.vue';


export const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: 'active',
    routes: [
        { 
            path: '/', component: User 
        },
        { 
            name: 'login',
            path: '/login', component: Login,
        },
        { 
            name: 'users',
            path: '/users', component: User,
        },
        { 
            name: 'properties',
            path: '/properties', component: Property,
        },
        {
            name: 'analytics',
            path: '/analytics', component: Analytics,
        },
        {
            name:'clients',
            path:'/clients', component:Client
        },
        {
            name:'property-type',
            path:'/property-types',component: PropetyType
        },
        {
            name:'filters',
            path:'/filters',component:FilterLayout
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    // Redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authStore = useAuthStore();
    const authRequired = !publicPages.includes(to.path);
    console.log(to);
    if (authRequired && !authStore.user) {
        authStore.returnUrl = to.fullPath;
        next({ name: 'login' });
    } else {
        next();
    }
});
