<template>
    <v-card class="elevation-0 rounded-sm">
        <v-card-text>
            <v-row>
                <v-col cols="12" class="d-flex">
                    <h1 style="font-size: 16px;">Listado de propiedades</h1>
                    <v-spacer></v-spacer>
                    <DefaultButton
                        label="Crear Propiedad"
                        text
                        flat
                        slim
                        outlined
                        variant='outlined'
                        @click="openModal(propertyStore.propertyModalOptions)"
                    />
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12">
                    <DefaultDataTable 
                        ref="propertyTable"
                        :data-table="dataTable"
                        :filters="filters"
                        @copy="copyUrl"
                        @edit="editProperty"
                        @list_medias="listMedias"
                        @delete="deleteResource"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <PropertyFormModal
            ref="propertyFormModal"
            @confirm="refreshTable(propertyTable)"
        />
        <MultimediaFormModal
            ref="multimediaFormModal"
        />
        <DefaultDeleteDialog 
            ref="deleteDialog"
            @deleted="acceptDelete"
        />
    </v-card>    
</template>
<script setup>
    import {ref} from 'vue';
    import DefaultDataTable from '@/components/basics/DefaultDataTable.vue';
    import DefaultButton from '@/components/basics/DefaultButton.vue';
    import DefaultDeleteDialog from '@/components/basics/DefaultDeleteDialog.vue';
    import PropertyFormModal from './PropertyFormModal'
    import MultimediaFormModal from './MultimediaFormModal'
    import { openModal,refreshTable } from '@/composables/modal';
    import { useToast } from '@/composables/useToast';
    
    import { usePropertyStore } from '@/stores';
    const { showToast } = useToast();

    const propertyTable = ref(null);
    const deleteDialog = ref(null);
    const dataTable = 
        {
            endpoint: '/manager/v1/properties',
            headers: [
                {title: "NOMBRE", value: "name", align: 'center', sortable: false},
                {title: "Tipo de propiedad", value: "property_name", align: 'center', sortable: false},
                {title: "PRECIO", value: "price"},
                {title: "UBICACION", value: "location"},
                // {title: "ÁREA TOTAL", value: "total_area", align: 'center', sortable: false},
                {title: "OPCIONES", value: "actions", align: 'center', sortable: false},
            ],
            actions: [
                {
                    text: "Editar",
                    icon: 'mdi mdi-pencil',
                    type: 'action',
                    method_name: 'edit'
                },
                {
                    text: "Multimedia",
                    icon: 'mdi mdi-image-area',
                    type: 'action',
                    method_name: 'list_medias'
                },
                {
                    text: "URL",
                    icon: 'mdi mdi-content-copy',
                    type: 'action',
                    method_name: 'copy'
                },
                {
                    text: "Eliminar",
                    icon: 'mdi mdi-delete',
                    type: 'action',
                    method_name: 'delete'
                },
            ],
            more_actions: [
                
            ]
        }
    const filters = {
        q: '',
    };
    const propertyStore = usePropertyStore();
    const propertyFormModal = ref(null);
    const multimediaFormModal = ref(null);
    /*FUNCTIONS*/
    const listMedias = async (property)=>{
        await propertyStore.listMedias(property.id);
        multimediaFormModal.value.resetMedia(property);
        openModal(propertyStore.mediaModalOptions,propertyStore.property,'Subir multimedias')
    }
    const editProperty = async (property)=>{
        await propertyStore.findProperty(property.id);
        propertyFormModal.value.resetProperty();
        openModal(propertyStore.propertyModalOptions,propertyStore.property)
    } 
    const copyUrl = (item) =>{
        navigator.clipboard.writeText('https://grim.com.pe/inmueble/'+item.slug);
        showToast('Se ha copiado en el portapapeles');
    }
    const deleteResource = (property) =>{
        deleteDialog.value.openDialog(property);
    }
    const acceptDelete = async(property) =>{
        await propertyStore.deleteProperty(property.id);
        refreshTable(propertyTable.value);
    }
</script>