import { defineStore } from 'pinia';

import AuthService from '@/services/auth.service';
import { router } from '@/router';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        status: { loggedIn: false }
    }),
    actions: {
        async login(identifier, password) {
            try {
                const user = await AuthService.login({ identifier, password });
                localStorage.setItem('user', JSON.stringify(user))
                this.status.loggedIn = true;
                this.user = user;
                router.push('/users');
            } catch (error) {
                this.status.loggedIn = false;
                this.user = null;
                throw error;
            }
        },
        async logout(){
            this.user = null;
            this.status.loggedIn = false;
            router.push('/login');
            setTimeout(async() => {
                await AuthService.logout();
            }, 1000);
        }
    }
});
