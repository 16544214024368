<template>
    <v-card>
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          :rail="rail"
          permanent
          @click="rail = false"
        >
          <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
            title="GRIM MASTER"
            nav
          >
            <template v-slot:append>
              <v-btn
                icon="mdi-chevron-left"
                variant="text"
                @click.stop="rail = !rail"
              ></v-btn>
            </template>
          </v-list-item>
          <v-divider></v-divider>
          <v-list density="compact" nav>
            <v-list-item prepend-icon="mdi-account-multiple" title="Usuarios" value="users" to="/users"></v-list-item>
            <v-list-item prepend-icon="mdi-city" title="Propiedades" value="properties" to="/properties"></v-list-item>
            <v-list-item prepend-icon="mdi-briefcase" title="Clientes" value="clients" to="/clients"></v-list-item>
            <v-list-item prepend-icon="mdi-chart-bar" title="Estadísticas" value="analytics" to="/analytics"></v-list-item>
            <v-list-item prepend-icon="mdi-nut" title="Filtros" value="filters" to="/filters"></v-list-item>
            <v-list-item prepend-icon="mdi-nut" title="Tipos de Propiedad" value="property-types" to="/property-types"></v-list-item>
            <v-list-item prepend-icon="mdi-keyboard-return" title="Cerrar sesión" @click="closeSession" style="cursor: pointer;"></v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-main style="background: #f4f8f9;">
            <v-container style="min-height: 100vh;background: #ffffff;">
              <router-view :key="$route.path"></router-view>
            </v-container>
        </v-main>
    </v-layout>
    </v-card>
  </template>
  <script setup>
    import { ref } from 'vue';
    import { useAuthStore } from '@/stores';
    //DATA
    let drawer = ref(true);
    let rail = ref(true);
    // FUNCTIONS
    const closeSession = async() =>{
      const authStore = useAuthStore();
      await authStore.logout();
    }
  </script>