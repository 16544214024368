<template>
    <section class="p-0" style="overflow-x:auto;">
        <v-data-table-server
            transition="fade"
            class="elevation-0"
            no-data-text="No se encontraron resultados"
            :loading="loading"
            loading-text="Cargando datos..."
            :headers="dataTable.headers"
            :items="rows"
            :items-per-page="pagination.per_page"
            items-per-page-text="Items por página"
            :items-per-page-options="[1,2,5,10,20]"
            :item-class="row_classes"
            :items-length="pagination.total_rows"
            @update:items-per-page="updateItemsPerPage"
            @update:page="changePage"
        >
        <!-- :server-items-length="pagination.total_rows" -->
        <!--  -->
        <!-- @update:sort-by="sortBy"
            @update:sort-desc="sortDesc"
            @update:items-per-page="updateItemsPerPage"
            :footer-props="footerProps" -->
            <!-- next-page-label="Siguiente"
            items-per-page-text="Items por página" -->

            <template v-slot:[`item.orden`]="{ item,header }">
                <div class="d-flex flex-column align-items-center">
                    <v-btn
                        text icon
                        color="primary"
                        @click="changeOrder(item, 'up', header.model, header.field)"
                        v-show="!dataTable.filters && item.orden && (!sortParams.sortBy || sortParams.sortBy == 'orden')"
                        :disabled="pagination.actual_page == 1 && rows.indexOf(item) == 0"
                    >
                        <v-icon>{{ mdi-chevron-up }}</v-icon>
                    </v-btn>
                    <span v-text="item.orden"></span>
                    <v-btn
                        text icon
                        color="primary"
                        @click="changeOrder(item, 'down', header.model, header.field)"
                        v-show="!dataTable.filters && item.orden && (!sortParams.sortBy || sortParams.sortBy == 'orden')"
                        :disabled="pagination.actual_page == pagination.total_pages && rows.indexOf(item) + 1 == rows.length"
                    >
                        <v-icon>{{ mdi-chevron-down }}</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`item.images`]="{ item }">
                <div class="d-flex justify-center ssflex-row my-2 " style="gap: 5px;"
                     v-if="item.images">
                    <v-img
                        v-for="(row, index) in item.images"
                        max-height="50"
                        max-width="50"
                        :key="index"
                        :src="row.image"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </div>
            </template>
            <template v-slot:[`item.tags`]="{ item }">
                <div class="d-flex justify-center ssflex-row my-2 " style="gap: 5px;"
                     v-if="item.tags">

                    <v-chip color="primary" small class="mx-1"
                            v-for="(row, index) in item.tags"
                            :key="index"
                    >
                        #{{ row.nombre }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex justify-center flex-row my-2"
                     v-if="item.status">
                    <v-chip
                        class="ma-2 white--text"
                        :color="item.status.color"
                    >
                        {{ item.status.text || item.status }}
                    </v-chip>
                </div>
            </template>
            <template v-slot:[`item.image`]="{ item }">
                <div class="d-flex justify-center flex-row my-2"
                     v-if="item.image">
                    <v-img
                        max-height="70"
                        max-width="70"
                        :src="item.image"
                    >
                        <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="default-table-actions d-flex justify-center flex-row my-2"
                     v-if="dataTable.actions && dataTable.actions.length > 0">
                    <!-- {{ item }} -->
                    <div v-for="(action,index) in dataTable.actions" :key="index">
                        <button
                            type="button" class="btn btn-md px-4"
                            :title="action.text"
                            @click="doAction(action, item)"
                            v-if="!action.show_condition || (action.show_condition && item[action.show_condition])"
                            :class="{'default-table-disable-action-btn' : action.disable_btn || (action.disable_btn && item[action.disable_btn])}"
                        >
                            <v-badge
                                v-if="(action.count && item[action.count])"
                                :color="item.active ? 'primary': 'grey'"
                                :content="item[action.count]"
                            >
                                <i :class="action.method_name == 'status' ? (item.active ? action.icon : 'far fa-circle')  : action.icon"/>
                                <br> <span class="table-default-icon-title" v-text="action.text"/>
                            </v-badge>

                            <template v-else>
                                <i :class="action.method_name == 'status' ? (item.active ? action.icon : 'far fa-circle')  : action.icon"/>
                                <br> <span class="table-default-icon-title" v-text="action.text"/>
                            </template>
                        </button>
                        <!-- <span class="badge table_default_badge-notify"
                              v-if="action.count"
                              v-text="item[action.count]"/> -->
                    </div>
                </div>
            </template>

        </v-data-table-server>
        <!--   Custom Paginator -->
<!-- 
        <section>
            <v-row class="justify-content-end" no-gutters>
                <v-col cols="4" lg="2" sm="3" class="d-flex justify-content-end">
                    <v-select
                        v-if="!hideRowsPerPage"
                        dense
                        hide-details="auto"
                        attach
                        :items="[10,15,20,25,30]"
                        v-model="pagination.per_page"
                        :menu-props="{ top: true, offsetY: true }"
                        class="table-default-items-per-page"
                    >
                        <template v-slot:prepend>
                            <div class="d-flex align-items-center">
                                <small v-text="'Filas por página'"/>
                            </div>
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="4" lg="1" sm="2" class="d-flex align-items-end justify-content-around">
                    <small
                        v-text="`${pagination.fromRow} - ${pagination.toRow} de ${pagination.total_rows}`"/>
                </v-col>
                <v-col cols="4" lg="1" sm="1" class="d-flex align-items-center justify-content-around">
                    <v-icon :disabled="pagination.actual_page === 1" v-text="'mdi-chevron-left'"
                            @click="changePage(false)"/>
                    <v-icon :disabled="pagination.actual_page === pagination.total_pages" v-text="'mdi-chevron-right'"
                            @click="changePage(true)"/>
                </v-col>
            </v-row>
        </section> -->
        <!--   ===================================================  -->

    </section>

</template>

<script>

export default {
    props: {
        dataTable: {
            required: true
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Object,
        },
        hideRowsPerPage: {
            type: Boolean,
            default: false
        },
        rowsPerPage: {
            type: Number,
            default: 10
        },
        defaultSortBy: {
            type: String,
            default: null
        },
        defaultSortDesc: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // data: () => ({
            last_sorted: Date.now(),
            loading: false,
            sortParams: {
                sortBy: this.defaultSortBy,
                sortDesc: this.defaultSortDesc,
            },
            more_actions_selectedItem: null,
            pagination: {
                total_pages: 1,
                actual_page: 1,
                // per_page: 5,
                per_page: this.rowsPerPage,
                fromRow: 1,
                toRow: 1,
                total_rows: 0,
            },
            rows: [],
            selectedRows: [],
            footerProps: {
                'items-per-page-options': [1, 15, 20, 25, 30],
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'page-text':'products per page'
            }
        }
        // ),
    },
    mounted() {
        // let vue = this;
        let filters = this.addParamsToURL(this.dataTable.filters, this.filters)
        this.getData(filters)
    },
    watch: {
        sortParams: {
            handler() {

                let vue = this;

                let new_date = Date.now()

                let time_passed = new_date - vue.last_sorted

                if (time_passed > 50) {
                    let filters = vue.addParamsToURL(vue.dataTable.filters, vue.filters)

                    vue.getData(filters)

                    vue.last_sorted = new_date
                } else {
                    console.log('time_passed not enough')
                }

            },
            deep: true
        }
    },
    methods: {
        TypeOf(x) {
            if (x === null) {
                return 'null';
            }

            switch (typeof x) {
                case "undefined":
                    return "undefined";
                case "boolean":
                    return "boolean";
                case "number":
                    return "number";
                case "string":
                    return "string";
                default:
                    if (Array.isArray(x)) return "array";
                    return "object";
            }
        },
        addParamsToURL(mainArrayStr, filter) {
            mainArrayStr = "";
            for (const prop in filter) {
                let objValue = filter[prop];
                if (this.TypeOf(objValue) !== "undefined") {
                    if (this.TypeOf(objValue) === "array") {
                        // console.log(prop, objValue)
                        mainArrayStr += this.addValuesFromArrayToUrl(objValue, prop);
                    } else {
                        // 	if (TypeOf(objValue) === 'boolean') {
                        // 	mainArrayStr += `&${prop}=${objValue}`
                        // }	else{
                        if (objValue) mainArrayStr += `&${prop}=${objValue}`;
                    }
                }
            }
            return mainArrayStr;
        },
        isAvailableMoreActions() {
            return true
        },
        row_classes(item) {
            if (item.active == false) {
                return "row-inactive"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        getData(filters = '', page = null) {
            let vue = this
            vue.loading = true
            if (page)
                vue.pagination.actual_page = page

            let url = `${vue.dataTable.endpoint}?` +
                `page=${page || vue.pagination.actual_page}` +
                `&paginate=${vue.pagination.per_page}`

            if (vue.sortParams.sortBy) // Add param to sort result
                url += `&sortBy=${vue.sortParams.sortBy}`

            if (vue.sortParams.sortDesc) // Add param to sort orientation
                url += `&sortDesc=${vue.sortParams.sortDesc}`

            url += filters
            this.$http.get(url)
                .then(({data}) => {
                    vue.rows = data.data

                    // if (vue.filters !== "")
                    if (vue.pagination.actual_page > data.total_pages)
                        vue.pagination.actual_page = data.total_pages

                    vue.pagination.total_pages = data.last_page;
                    vue.pagination.fromRow = data.from || 0;
                    vue.pagination.toRow = data.to || 0;
                    vue.pagination.total_rows = data.total;
                    console.log(vue.pagination,'pagination');
                    vue.loading = false
                })
        },
        changePage(sum) {
            let vue = this;
            vue.pagination.actual_page= sum;
            let filters = vue.addParamsToURL(vue.dataTable.filters, vue.filters)
            vue.getData(filters)
        },
        updateItemsPerPage(rowsPerPage) {
            let vue = this
            vue.pagination.per_page = rowsPerPage
            let filters = vue.addParamsToURL(vue.dataTable.filters, vue.filters)
            vue.getData(filters)
        },
        doAction(action, rowData) {
            let vue = this
            if (action.type === 'action') { // Emit parent method
                // console.log("METHOD EMIT :: ", action.method_name)
                vue.$emit(action.method_name, rowData)
            } else { // Navigate
                // console.log('navegar')
                if (action.route) {
                    if (action.route_type == 'external') {
                        window.open(rowData[action.route]);
                    } else {
                        window.location.href = rowData[action.route]
                    }
                }
            }
        },
        sortBy(sortProperty) {
            // console.log('sortBy')
            // console.log(sortProperty)
            let vue = this
            let value = sortProperty.length > 0 ? sortProperty[0] : null

            if (vue.sortParams.sortBy != value) {
                // console.log('sortBy changed')
                vue.sortParams.sortBy = value
            }

            // let filters = vue.addParamsToURL(vue.dataTable.filters, vue.filters)

            // vue.getData(filters)
        },
        sortDesc(sortDesc) {
            // console.log('sortDesc')
            // console.log(sortDesc)
            let vue = this
            let value = sortDesc.length > 0 && vue.sortParams.sortBy !== null ? sortDesc[0] : false

            if (vue.sortParams.sortDesc != value) {
                // console.log('sortDesc changed')
                vue.sortParams.sortDesc = value

                // let vue = this;

            }
        },
        onSelectRow() {
            let vue = this
            vue.$emit('onSelectRow', vue.selectedRows)
        },
        // ORDER METHODS
        statusUp() {

        },
        statusDown() {

        },
        changeOrder(item, action, model, field = 'orden') {
            let vue = this
            this.showLoader()
            let new_action = action == 'down' ? 'up' : 'down';
            action = vue.sortParams.sortDesc ? action : new_action;
            let url = `/common/change_order`
            let data = {
                id: item.id,
                model,
                action,
                field,
            }
            vue.$http.put(url, data)
                .then(({data}) => {
                    vue.showAlert(data.data.msg)
                    let filters = vue.addParamsToURL(vue.dataTable.filters, vue.filters)
                    vue.getData(filters)
                    this.hideLoader()
                })

            this.hideLoader()
        },

    }
}
</script>

<style lang="scss">

</style>
