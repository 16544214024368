<script setup>
import  SideMenu from '@/components/SideMenu.vue';
import { useAuthStore } from '@/stores';
const authStore = useAuthStore();
</script>
<template>
    <div class="app-container"  v-if="authStore.user">
        <SideMenu />
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
</template>