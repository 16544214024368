import axios from '@/plugins/axios';
import { formatFormData } from "@/composables/services.js";

class PropertyTypeService {
  async storePropertyType(propertyType) {
    const propertyTypeFormData = formatFormData(propertyType, "POST");
    const response = await axios
      .post('/manager/v1/properties/types', propertyTypeFormData,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({data}) => {
        return data.data;
      }).catch((e)=>{
        throw e;
      });
    return response;
  }

  async updatePropertyType(propertyType) {
    const propertyTypeFormData = formatFormData(propertyType, "PUT");
    const response = await axios
      .post(`/manager/v1/properties/types/${propertyType.id}`, propertyTypeFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({data}) => {
        return data.data;
      }).catch((e)=>{
        throw e;
      });
    return response;
  }

  async findPropertyType(id){
    let client = {};
     await axios
      .get(`/manager/v1/properties/types/${id}`)
      .then(({data}) => {
        client = data.data;
      }).catch((e)=>{
        throw e;
      });
    return client;
  }
  async deletePropertyType(property_type_id){
    await axios.delete(`/manager/v1/properties/types/${property_type_id}`).then(() => {
      }).catch((e)=>{
        throw e;
      });
  }
  async listFilters(){
    let filters = {};
     await axios
      .get(`/manager/v1/filters/all`)
      .then(({data}) => {
        filters = data.data;
      }).catch((e)=>{
        throw e;
      });
    return filters;
  }
  async listPropertyTypes(){
    let property_types = [];
    await axios
      .get(`/manager/v1/properties/types/all`)
      .then(({data}) => {
        property_types = data.data;
      }).catch((e)=>{
        throw e;
      });
    return property_types;
  }
  async listFiltersByPropertyType(property_type_id){
    let filters = [];
    await axios
      .get(`/manager/v1/properties/types/${property_type_id}/filters`)
      .then(({data}) => {
        filters = data.data;
      }).catch((e)=>{
        throw e;
      });
    return filters;
  }
}

export default new PropertyTypeService();
