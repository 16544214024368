import { defineStore } from 'pinia';
import PropertyService from '@/services/property.service';
export const usePropertyStore = defineStore({
    id: 'property',
    state: () => ({
        propertyModalOptions: {
            open: false,
            reference: null,
            title: 'Crear Propiedad'
        },
        mediaModalOptions: {
            open: false,
            reference: null,
            title: 'Subir Multimedia',
            resource:null
        },
        propertyDefault: {
            id: null,
            name: "",
            description: "",
            slug: "",
            city: '',
            district: '',
            property_type_id: null,
            active: true,
            city_id: null,
            province_id: null,
            district_id: null,
            price_PEN:'',
            price_USD:'',
            features: [],
            transaction_type:'venta',
            map_address:'',
            custom_address:'',
            latitude :'',
            longitude:'',
        },
        property: {
            id: null,
            name: "",
            description: "",
            slug: "",
            city: '',
            district: '',
            property_type_id: null,
            active: true,
            city_id: null,
            province_id: null,
            district_id: null,
            price_PEN:'',
            price_USD:'',
            features: [],
            transaction_type:'venta',
            map_address:'',
            custom_address:'',
            latitude :'',
            longitude:'',
        },
        medias: {}
    }),
    getters: {
        stateProperty: (state) => state.property,
        stateMedias: (state) => state.medias,
    },
    actions: {
        async findProperty(property_id) {
            try {
                const property = await PropertyService.findProperty(property_id);
                this.property = property;
            } catch (error) {
                this.property = null;
                throw error;
            }
        },
        async storeProperty(property) {
            try {
                await PropertyService.storeProperty(property);
                this.property = null;
            } catch (error) {
                this.property = null;
                throw error;
            }
        },
        async listMedias(property_id) {
            try {
                this.medias = await PropertyService.listMedias(property_id);
                this.property = null;
            } catch (error) {
                this.property = null;
                throw error;
            }
        },
        async saveMedia(property_id,medias){
            return await PropertyService.saveMedias(property_id,medias);
        },
        async updateMultimedia(property_id,image){
            return await PropertyService.updateMultimedia(property_id,image);
        },
        async deleteMultimedia(property_id,image){
            return await PropertyService.deleteMultimedia(property_id,image);
        },
        async updateProperty(property) {
            try {
                await PropertyService.updateProperty(property);
                this.property = { ...this.propertyDefault };
                this.property = null;
            } catch (error) {
                this.property = null;
                throw error;
            }
        },
        async deleteProperty(property_id){
            await PropertyService.deleteProperty(property_id);
        },
        restoreProperty() {
            this.property = { ...this.propertyDefault };
        },
        restoreMedia() {
            this.medias = {};
        },
        async listCities(){
            return await PropertyService.listCities();
        },
        async listProvincesByCity(city_id) {
            return await PropertyService.listProvincesByCity(city_id);
        },
        async listDistrictsByProvince (province_id){
            return await PropertyService.listDistrictsByProvince(province_id);
        }
    }
});
