import axios from "axios";
import { useToast } from '@/composables/useToast';
const { showToast } = useToast();

const baseUrl = `${process.env.VUE_APP_API_URL}/api`;
let token = '';
if(localStorage.user){
    const user = JSON.parse(localStorage.user);
    token = user.token;
}
let axiosDefault = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
});

axiosDefault.interceptors.request.use(
    function (config) {
        const user = JSON.parse(localStorage.getItem('user'));
        config.headers.Authorization =  user ? `Bearer ${user.token}` : '';
        // config.headers.common['Authorization'] = `Bearer ${newToken}`;
        // config.headers.common['Authorization'] =  user ? `Bearer ${user.token}` : '';
        return config
    },
    function (error) {

        return Promise.reject(error);
    },
);

axiosDefault.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // response.data = {hai: 'hai'};
        // console.log(response)
        // let tempObjResponse = {
        //     urlResponse: response.request.responseURL,
        // }
        // console.table(tempObjResponse)
        // console.info('DATOS RECIBIDOS :: ', response.data.data)
        // this.hideLoader()
        if(response.data.message && response.status == '200'){
            showToast(response.data.message)
        }
        return response;
    },
    async function (error) {
        // Cualquier código de estado que esté fuera del rango de 2xx hace que esta función se active
        // Haz algo con el error de respuesta
        // localStorage.removeItem('user');
        // window.location.reload();
        if(error.response.status){
            switch (error.response.status) {
                case 419:
                    // Manejar el error 419
                    break;
                case 401:
                    localStorage.removeItem('user');
                    window.location.reload();
                    // router.push('/login');
                    return Promise.reject(error.response.data);
                case 422:
                    // Manejar el error 422
                    return Promise.reject(error.response.data);
                case 404:
                    // Manejar el error 422
                    return Promise.reject(error.response.data);
                case 500:
                    // Manejar el error 500
                    console.error("Error interno del servidor:", error.response.data);
                    // Retornar el error como una promesa rechazada
                    return Promise.reject(error.response.data);
                default:
                    // Retornar el error como una promesa rechazada
                    return Promise.reject(error);
            }
        }
    }
);
// export function updateToken(newToken) {
//     console.log('newtoken',newToken);
//     axiosDefault.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
// }
export default axiosDefault;
