<template>
    <v-dialog
      :width="width"
      scrollable
      v-model="dialogOpen"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-center"  v-if="showCardTitle">
          {{ options.title }}
          <v-spacer></v-spacer>
          <v-icon @click="onClose(true)">mdi-close</v-icon>
        </v-card-title>
        <v-divider class="mt-3" v-if="showCardTitle"></v-divider>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-text :class="padding ? 'px-4' : 'px-0 py-0'" style="height: auto;">
          <slot name="body"></slot>
        </v-card-text>
        <v-card-actions class="d-flex justify-center" v-if="showCardActions">
          <DefaultButton
            :label="cancelLabel"
            @click="onClose"
            color="black"
            class="mx-6"
            :disabled="loading"
          />
          <DefaultButton
            :label="confirmLabel"
            @click="onConfirm"
            variant="tonal"
            class="mx-6"
            :disabled="loading"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script setup>
    import DefaultButton from '@/components/basics/DefaultButton.vue';
    import { defineProps,ref,watch,defineEmits } from 'vue';
    const emit = defineEmits(['closeModal','confirmModal'])

    const props = defineProps({
        options: {
            type: Object,
            default: () => ({
                title: 'Modal',
                open:false
            })
        },
        width:{
          type:String,
          default: '600px'
        },
        showCardActions:{
          type:Boolean,
          default:true
        },
        showCardTitle:{
          type:Boolean,
          default:true
        },
        padding:{
          type:Boolean,
          default:true
        },
        loading:{
          type:Boolean,
          default:false
        },
        cancelLabel:{
          type:String,
          default:'Cancelar'
        },
        confirmLabel:{
          type:String,
          default:'Guardar'
        }
    });
    const dialogOpen = ref(props.options.open);
    watch(() => props.options.open, (newValue) => {
      dialogOpen.value = newValue;
    });
    const onClose = (directClose = false) => {
      if(!props.loading){
        emit('closeModal',directClose);
      }
    }
    const onConfirm = () =>{
      emit('confirmModal');
    }
</script>
  