<template>
    <DefaultDialog :options="propertyModalOptions" @closeModal="prev" @confirmModal="next" width="1000px"
         :padding="false" 
         cancelLabel="Atras"
         :confirmLabel="step==2 ? 'Guardar' : 'Siguiente'"
         :loading="loading"
    >
        <template v-slot:body>
            <v-form ref="propertyForm" class="p-0" @submit.prevent="()=>{}">
                <v-stepper :next-text="`${step == 2 ? 'Guardar' : 'Siguiente'}`" prev-text="Atras" v-model="step">
                    <template v-slot:default="{  }">
                        <v-stepper-header>
                            <v-stepper-item complete editable title="General" value="1" @click="step=0"></v-stepper-item>
                            <v-divider></v-divider>
                            <v-stepper-item editable title="Características" value="2" @click="step=1"></v-stepper-item>
                            <v-divider></v-divider>
                            <v-stepper-item editable title="Ubicación" value="3" @click="step=2"></v-stepper-item>
                        </v-stepper-header>
                        <v-stepper-window>
                            <v-stepper-window-item value="1">
                                <v-row class="mt-1">
                                    <v-col cols="12">
                                        <v-text-field 
                                            v-model="property.name"
                                            label="Título" 
                                            variant="outlined" 
                                            required
                                            :rules="rules.required"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            label="Tipo de transacción"
                                            :items="transaction_types"
                                            v-model="property.transaction_type"
                                            variant="outlined"
                                            :rules="rules.required"
                                        ></v-select>
                                    </v-col>
                                    
                                    <v-col cols="6">
                                        <v-select
                                            label="Tipo de propiedad"
                                            :items="property_types"
                                            v-model="property.property_type_id"
                                            variant="outlined"
                                            :rules="rules.required"
                                            item-title="name"
                                            item-value="id"
                                            @update:modelValue="listFiltersByPropertyType"
                                        ></v-select>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-textarea 
                                            label="descripción" 
                                            variant="outlined"
                                            v-model="property.description"
                                            :rules="rules.required"
                                        >
    
                                        </v-textarea>
                                    </v-col>
                                </v-row>
                            </v-stepper-window-item>
                            <v-stepper-window-item value="2">
                                <v-row class="mt-1">
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Precio en dolares"
                                            variant="outlined" 
                                            v-model="property.price_USD"
                                            :rules="rules.required"
                                            prefix="$"
                                            ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            label="Precio en soles"
                                            variant="outlined" 
                                            v-model="property.price_PEN"
                                            :rules="rules.required"
                                            prefix="S/"
                                            ></v-text-field>
                                    </v-col>
                                    <v-col cols="6" class="text-center" v-for="(feature,index) in property.features" :key="index">
                                        <!-- prepend-icon="mdi-minus"
                                        append-icon="mdi-plus"
                                        @click:append="addValue"
                                        @click:prepend="lessValue" -->
                                        <v-text-field
                                            v-if="feature.input_type == 'number_range'"
                                            v-model="feature.value"
                                            clear-icon="mdi-close-circle"
                                            :label="feature.name"
                                            :rules="feature.value ? rules.number : []"
                                            type="text"
                                            variant="outlined" 
                                        ></v-text-field>
                                        <v-select
                                            v-if="feature.input_type == 'select'"
                                            v-model="feature.filter_value_id"
                                            :label="feature.name"
                                            :items="feature.values"
                                            variant="outlined"
                                            item-title="name"
                                            item-value="id"
                                            :multiple="feature.multiple"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-stepper-window-item>
                            <v-stepper-window-item value="3">
                                <v-row class="mt-1">
                                    <v-col cols="4">
                                        <v-autocomplete
                                            v-model="property.city_id"
                                            label="Ciudad"
                                            :items="cities"
                                            variant="outlined"
                                            :rules="rules.required"
                                            item-title="name"
                                            item-value="id"
                                            @update:modelValue="listProvincesByCity"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete
                                            v-model="property.province_id"
                                            label="Provincia"
                                            :items="provinces"
                                            variant="outlined"
                                            :rules="rules.required"
                                            item-title="name"
                                            item-value="id"
                                            @update:modelValue="listDistrictsByProvince"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-autocomplete
                                            v-model="property.district_id"
                                            label="Distrito"
                                            :items="districts"
                                            variant="outlined"
                                            :rules="rules.required"
                                            item-title="name"
                                            item-value="id"
                                        ></v-autocomplete>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field
                                            label="Nombre Personalizado"
                                            variant="outlined" 
                                            v-model="property.custom_address"
                                            :rules="rules.required"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <div class="position-relative">
                                            <v-text-field 
                                                class="mb-0 pb-0"
                                                v-model="query"
                                                label="Buscar lugar" 
                                                variant="outlined" 
                                                @input="searchPlaces"
                                                hide-details
                                            />
                                            <ul class="list-places">
                                                <li v-for="(place, index) in places" :key="index" @click="setValueMarker(place)" class="place-element">
                                                    {{ place.name }}
                                                </li>
                                            </ul>
                                            <input type="text" id="latitude" :value="property.latitude" style="visibility:hidden;">
                                            <input type="text" id="longitude" :value="property.longitude" style="visibility:hidden;">
                                            <div ref="mapContainer" id="map" style="height: 400px; width: 100%;"></div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-stepper-window-item>
                        </v-stepper-window>
                    </template>
                </v-stepper>
            </v-form>
        </template>
    </DefaultDialog>
</template>

<script setup>
/* IMPORTS */
import { ref,defineExpose,onMounted,defineEmits } from 'vue';
import { storeToRefs } from 'pinia'
import DefaultDialog from '@/components/basics/DefaultDialog.vue';
import { usePropertyStore,usePropertyTypeStore } from '@/stores'
import { getRules,validateForm } from '@/composables/validations';
import useGoogleMaps from '@/composables/map';

const { query, places, mapContainer, searchPlaces, setMarker,clearMap,map_latitude,map_longitude } = useGoogleMaps();
/* DATA */
const loading = ref(false);
const emit = defineEmits(['confirm'])
const cities = ref([]);
const provinces = ref([]);
const districts = ref([]);

const propertyStore = usePropertyStore();
const { propertyModalOptions } = storeToRefs(propertyStore)

const propertyTypeStore = usePropertyTypeStore();
const transaction_types = [
    'venta','alquiler'
];
const propertyForm = ref(null);
const rules = {
    required: getRules(['required']),
    email: getRules(['required', 'email']),
    password: getRules(['required']),
    number : getRules(['number'])
}
let property = ref({ ...propertyStore.stateProperty });
const step = ref(0);
const property_types = ref([]);
// const filters = ref([]);
/*FUNCTIONS*/
const resetProperty =  async() =>{
    property.value ={ ...propertyStore.stateProperty };
    const district_id = property.value.district_id;
    await listProvincesByCity();
    property.value.district_id = district_id;
    await listDistrictsByProvince();
}
const closeModal = () => {
    propertyStore.restoreProperty();
    property.value = {...propertyStore.propertyDefault};
    provinces.value = [];
    districts.value = [];
    step.value = 0;
    propertyModalOptions.value.open = false;
    clearMap();
}
const sendForm = async () => {
    const { valid } = await validateForm(propertyForm);
    if (valid) {
        property.value.map_address = query;
        property.value.latitude = map_latitude.value;
        property.value.longitude = map_longitude.value;
        loading.value = true;
        if (property.value.id) {
            await propertyStore.updateProperty(property.value);
        } else {
            await propertyStore.storeProperty(property.value);
        }
        loading.value = false;
        closeModal();
        emit('confirm')
    }
};

const prev = (directClose) => {
    if(directClose){
        closeModal();
        return;
    }
    if(step.value == 0){
        return;
    }
    step.value = step.value - 1;
}
const next = async() => {
    if(step.value == 0){
        if(!property.value.name || !property.value.property_type_id || !property.value.description){
            await validateForm(propertyForm);
            return '';
        }
    }
    if(step.value == 1){
        const featuresValidationTypeNumber = property.value.features.filter(({ input_type, value }) => {
            return input_type === 'number_range' && value && isNaN(value);
        });
        
        if(!property.value.price_PEN || !property.value.price_USD || featuresValidationTypeNumber.length > 0){
            await validateForm(propertyForm);
            return '';
        }
    }
    if(step.value == 2){
        await sendForm();
        return;
    }
    step.value = step.value + 1;
    console.log(step.value);
}
const listFiltersByPropertyType = async() => {
    provinces.value = [];
    property.value.province_id = null;
    districts.value = [];
    property.value.district_id = null;
    property.value.features = await propertyTypeStore.listFiltersByPropertyType(property.value.property_type_id);
}
const listProvincesByCity = async()=>{
    districts.value = [];
    property.value.district_id = null;
    provinces.value = await propertyStore.listProvincesByCity(property.value.city_id);
}
const listDistrictsByProvince = async() => {
    districts.value =  await propertyStore.listDistrictsByProvince(property.value.province_id);
}
const removeAccents = (str) =>{
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

const setValueMarker = async (place) =>{
    const {city,latitude,pronvince,longitude,district} = await setMarker(place);
    property.value.latitude = latitude;
    property.value.longitude = longitude;
    const _city = cities.value.find(c => 
        removeAccents(city.toLowerCase()).includes(removeAccents(c.name.toLowerCase()))
    );
    if(_city){
        property.value.city_id = _city ? _city.id : null; 
        await listProvincesByCity();
        const _province = provinces.value.find(c => 
            removeAccents(pronvince.toLowerCase()).includes(removeAccents(c.name.toLowerCase()))
        );
        if(_province){
            property.value.province_id = _province ? _province.id : null;
            await listDistrictsByProvince();
            const _district = districts.value.find(c => 
                                removeAccents(district.toLowerCase()).includes(removeAccents(c.name.toLowerCase()))
                            );
            if(_district){
                property.value.district_id = _district ? _district.id : null;
            }
        }
    }
}
/*EVENTOS*/
onMounted(async () => {
    property_types.value = await propertyTypeStore.listPropertyTypes();
    cities.value = await propertyStore.listCities();
})

defineExpose({
    resetProperty
})
</script>
<style scoped>

.drag-drop-area {
    border: 3px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: auto;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.drag-drop-area p {
  margin-bottom: 10px;
}
.list-places{
    max-height: 130px;
    z-index: 20;
    position: absolute;
    background: white;
    width: 100%;
    overflow-y: auto;
}
.place-element{
    padding: 8px 0px 8px 10px;
    cursor: pointer;
}
.place-element:hover{
    background: skyblue;
}
.styled-input {
    width: 80%; 
    font-size: 16px; 
    border: 2px solid #ccc;
    border-radius: 5px; 
    box-sizing: border-box; 
    margin-top:10px;
}
</style>